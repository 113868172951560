import React, { useEffect } from "react";

import Layout from "../components/layout/Layout";
import Slider from "../components/slider/Slider.js";
import Categories from "../components/categories/Categories.js";
import { ProductsTab } from "../components/product/feed/Product";
import Promotions from "../components/promotions/Promotions";
import LatestBlog from "../components/blog/posts/Posts";
import sliderData from "../data/slider/home-1.json";
import navData from "../data/nav/index.json";
import setData from "../data/sets/index.json";
import cataloguesData from "../data/catalogues/index.json";

const Home = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <Layout>
      <Slider animate={true} data={sliderData} />

      <Categories categories={navData} />

      <ProductsTab products={setData} limit={8} />

      <Promotions />

      <LatestBlog posts={cataloguesData} pt={[60, 60, 100]} />
    </Layout>
  );
};

export default Home;
