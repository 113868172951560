import Layout from "../../components/layout/Layout";
import Breadcrumb from "../../components/ui/breadcrumb/Breadcrumb";
import { useParams } from "react-router-dom";
import ShopProductsFeed from "../../components/shop/ShopProductsFeed";
import products from "../../data/products/index.json";
import React, { useEffect } from "react";

const ShopPage = () => {
  const { name } = useParams();

  useEffect(() => {
    window.scrollTo({
      top: 100,
      left: 0,
      behavior: 'smooth'
    });
  }, [name]);

  return (
    <Layout>
      <Breadcrumb
        py={[40, 80]}
        mb={[60, null, 100]}
        pageTitle={name?.replaceAll("_", " ")}
        path={"/shop/" + name}
      />

      <ShopProductsFeed products={products} />
    </Layout>
  );
};

export default ShopPage;
