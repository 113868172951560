import { Fragment } from "react";
import Image from "../../ui/image/Image";
import Slider, { Slide } from "../../ui/swiper/Slider";
import { ProductThumbGallery } from "./details.style";

const ProductDetailsThumb = ({ thumbnails }) => {
  const thumbGalleryConfig = {
    pagination: false,
  };

  return (
    <Fragment>
      <ProductThumbGallery>
        <Slider settings={thumbGalleryConfig}>
          {thumbnails?.map((image) => (
            <Slide key={image?.id}>
              <figure>
                <Image layout="fill" alt="unico" src={image?.originalSrc} />
              </figure>
            </Slide>
          ))}
        </Slider>
      </ProductThumbGallery>
    </Fragment>
  );
};

export default ProductDetailsThumb;
