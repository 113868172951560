import cn from "classnames";
import { useState } from "react";
import Loader from "../../ui/loader/Loader";
import EmptyProduct from "../../ui/empty/Empty";
import ProductCard from "../card/Card";
import SectionTitle from "../../ui/section-title/SectionTitle";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Col, Container, Row } from "@bootstrap-styled/v4";
import { ProductNav } from "./style";

const productNav = [
  {
    key: "set-one",
    title: "Set 1",
  },
  {
    key: "set-two",
    title: "Set 2",
  },
  {
    key: "set-three",
    title: "Set 3",
  },
  {
    key: "set-four",
    title: "Set 4",
  },
  {
    key: "set-five",
    title: "Set 5",
  },
  {
    key: "set-six",
    title: "Set 6",
  },
  {
    key: "set-seven",
    title: "Set 7",
  },
  {
    key: "set-eight",
    title: "Set 8",
  },
];

const ProductsTab = ({ products, limit = 8, className }) => {
  const [data, setData] = useState(products.page1);

  const onHandler = (event) => {
    const target = event.target;
    const key = target.dataset.key;
    switch (key) {
      case "set-one":
        setData(products.page1);
        break;
      case "set-two":
        setData(products.page17);
        break;
      case "set-three":
        setData(products.page18);
        break;
      case "set-four":
        setData(products.page24);
        break;
      case "set-five":
        setData(products.page35);
        break;
      case "set-six":
        setData(products.page39);
        break;
      case "set-seven":
        setData(products.page42);
        break;
      default:
        setData(products.page58);
    }
  };

  return (
    <div className={cn(className)}>
      <Container>
        <Row>
          <Col xs={12}>
            <SectionTitle
              mb={42}
              align="center"
              title="Coycama"
              content="Step into a world of lavishness with bathroom accessories that embody luxury at its finest."
            />
          </Col>
        </Row>

        {!data && <Loader />}

        {data && (
          <Tabs>
            <ProductNav mb={[30, null, 55]} align="center">
              <TabList>
                {productNav.map((item) => (
                  <Tab
                    key={item?.key}
                    data-key={item?.key}
                    onClick={(event) => onHandler(event)}
                  >
                    {item?.title}
                  </Tab>
                ))}
              </TabList>
            </ProductNav>

            {productNav.map((item) => (
              <TabPanel key={item?.key}>
                <Row className="products-grid-mobile mtn-30">
                  {data?.length > 0 ? (
                    data?.slice(0, limit)?.map((product) => (
                      <Col xs={6} md={4} lg={3} key={product?.id}>
                        <ProductCard product={product} set={true}/>
                      </Col>
                    ))
                  ) : (
                    <div className="w-100">
                      <EmptyProduct />
                    </div>
                  )}
                </Row>
              </TabPanel>
            ))}
          </Tabs>
        )}
      </Container>
    </div>
  );
};

export default ProductsTab;
